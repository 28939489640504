.menu_sidebar {
    height: 100%;
    width: 250px;
    position: fixed;
    top: 65px;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    padding: 20px;
    z-index: 0;

    &[data-subheader='true'] {
        top: 130px !important;
    }
}
.nav_link {
    padding: 10px;
    color: #909399 !important;
    padding: 10px;
    margin-bottom: 5px;
    font-size: 14px !important;
}
.active {
    padding: 10px;
    color: #FFFFFF !important;
    background-color: #EB4B56;
    border-radius: 5px;
    font-weight: 700;
}
.nav_link > i {
    padding-right: 10px !important;
    font-size: 18px;
}
