.button-get-data {
  display: flex;
  flex-direction: row;
  justify-items: center;
  margin-top: -0.5em;
  align-items: center;
}

.__catatan_main {
  display: flex;
  flex-direction: row;
}

.__catatan_main .__catatan_delete {
  align-items: center;
  display: flex;
}

.__catatan_main .pointer {
  cursor: pointer;
}
