
.main-layout {
    width: calc(100% - 250px);
    position: absolute;
    top: 70px;
    left: 250px;
    padding: 20px 0 0 20px;
}

.main-layout.true {
    top: 135px;
}

.main-layout > .main {
    padding: 0 1rem;
}
  
@media only screen and (max-width: 600px) {
.flex-column.nav {
    display: none;
}
.main-layout {
    width: 100%;
    left: 0;
    padding: 10px 0 ;
}
}
