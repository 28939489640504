.__casting-select {
  width: 100%;
  padding: 0.5rem;
  color: rgba(27, 27, 27, 0.9);
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
}
.__card-keterangan {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@media only screen and (max-width: 768px) {
  .__card-keterangan {
    flex-direction: column;
  }
}

.opacity {
  opacity: 0;
}

.ellipsis-text {
  white-space: nowrap; /* Jangan biarkan teks membungkus ke baris baru */
  overflow: hidden; /* Sembunyikan teks yang melampaui batas elemen */
  text-overflow: ellipsis; /* Tambahkan "..." di akhir teks yang terpotong */
  width: 100%; /* Pastikan elemen memiliki lebar yang sesuai */
  display: block; /* Pastikan elemen tetap tampil sebagai block */
}

.__category-casting {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 3px;
}

.__category-casting div {
  white-space: nowrap;
}
