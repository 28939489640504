.__search-talent-sinemart {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.__search-talent-sinemart .__button-seacrh {
  width: 5rem;
}

.__eklusif-gender {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.__eklusif-gender .__eklusif {
  flex: 1;
}

.__eklusif-gender .__gender {
  flex: 1;
  margin-left: 0;
}

.__eklusif-gender .__available {
  flex: 1;
  margin-left: 0;
}

.__usia-tinggi-berat {
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.__text-bold {
  font-weight: 600;
}
