.header {
    position: fixed;
    width: 100%;
    z-index: 2;
}

.header .container {
    max-width: none !important;
    width: 95%;
}

.header .dropdown-menu {
    z-index: 2;
}

.header .dropdown-item:hover {
    cursor: pointer;
}

.header .dropdown-item:active {
    background-color: #EB4B56;
    color: #fff !important;
}


.notifikasi {
    font-size: 20px;
    cursor: pointer !important;
}

.notifikasi .dropdown-menu {
    inset: 0px 0px auto auto !important; 
    transform: translate(-40px, -10px) !important;
    margin-top: 2.85rem;
    padding: 0;
    width: 300px;
}

.notifikasi .dropdown-menu .value {
    max-height: 500px !important;
    overflow-y: auto;
}

.notifikasi .dropdown-header {
    max-width: 300px !important;
    padding: 20px 10px;
    border-bottom: 1px solid #E7E7E8;
    color: #3C3F45;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notifikasi .dropdown-header > h5 {
    font-weight: bold;
    margin-bottom: 0;
}

.notifikasi .dropdown-header > a {
    font-size: 13px;
}

.notifikasi .dropdown-item {
    max-width: 400px !important;
    padding: 10px;
    white-space: initial;
    text-align: justify;
    border-bottom: 1px solid #E7E7E8;
}

.notifikasi .dropdown-item i {
    color: #909399 !important;
}

.notifikasi .dropdown-item.active {
    background-color: #FEE9EA;
}

.notifikasi .dropdown-item .judul {
    color: #3B3C3D;
    font-weight: bold;
    font-size: 13px;
    margin: 2px 0;
}

.notifikasi .dropdown-item .jenis {
    font-weight: bold;
    padding: 5px 0;
}

.notifikasi .dropdown-item .isi, .notifikasi .dropdown-item .waktu {
    font-size: 13px;
    color: #919396;
}

.notifikasi .dropdown-item .waktu {
    margin-top: 5px;
}

.notifikasi > i {
    color: #3B3C3D;
}

.dot-notif{
    font-size: 12px;
    vertical-align: top;
    color: #DE3E4D;
}

.no-notif {
    text-align: center;
    margin: 10px;
}