.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: red !important;
}

 .ant-tabs-tab:hover{
    color: #EB4B56 !important;
}

/* .ant-tabs-tab.ant-tabs-tab-active{
    background-color: transparent !important;
    border-radius: 10px;
}  */

.ant-tabs-ink-bar{
    background: red !important
}

/* .ant-tabs-left > .ant-tabs-content-holder, .ant-tabs-left > div > .ant-tabs-content-holder{
    margin-left: 20px !important
    ;
} */

