[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
    margin-bottom: 24px;
    overflow: hidden;
    border: 0px;
    border-radius: 2px;
}

.ant-collapse {
    border: unset !important;
}
