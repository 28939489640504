.bg_sub_header {
    background: #FFFFFF;
    font-size: 20px;
    color: #3B3C3D;
    box-shadow: 0 4px 4px #33373d0c;
    position: fixed;
    top: 65px;
    z-index: 1;
}

.bg_sub_header i {
    font-size: 20px;
    color: #3B3C3D;
    line-height: 1.5;
}
