/* Costum CSS */
/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFWkU1Z4Y.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFU0U1Z4Y.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFVUU1Z4Y.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFWUU1Z4Y.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFV0U1.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


body {
  font-family: "Rubik" !important;
  background-color: #F7F7F7 !important;
}
.border-card {
  border-radius: 16px !important;
  border: none !important;
  box-shadow: 0 0 20px rgba(60, 63, 69, 0.06) !important;
}
.btn-danger {
  background: linear-gradient(360deg, #E04852 0%, #FF707A 100%) !important;
  border: none !important;
  border-radius: 4px;
  transition: none !important;
}
.btn-danger:hover {
  background: #E04852 !important;
  border: none !important;
  border-radius: 4px;
}
.btn-outline-danger:hover {
  background: linear-gradient(360deg, #E04852 0%, #E04852 100%) !important;
  color: #fff !important;
}
.btn-outline-danger {
  color: #EB4B56 !important;
  transition: none !important;
  background-color: #fff;
}
.btn-danger-soft {
  background-color: #FFD6DA !important;
  border: none !important;
  border-radius: 4px;
  color: #DE3E4D !important;
  box-shadow: none !important;
}
.btn-danger-soft:hover {
  background-color: #fabac1 !important;
  border: none !important;
  border-radius: 4px;
  color: #DE3E4D !important;
  box-shadow: none !important;
}
.btn-success-soft {
  background-color: #D6FFDB !important;
  border: none !important;
  border-radius: 4px;
  color: #00AA13 !important;
  box-shadow: none !important;
}
.btn-success-soft:hover {
  background-color: #b1f8b9 !important;
  border: none !important;
  border-radius: 4px;
  color: #00AA13 !important;
  box-shadow: none !important;
}
.form-label {
  color: #3C3F45 !important;
  font-size: 14px !important;
  font-weight: normal !important;
}
.pass-wrapper {
  position: relative;
  /* display: flex; */
  margin-bottom: 14px;
}
.pass-wrapper i {
  position: absolute;
  top: 60%;
  right: 5%;
}
.pass-wrapper i:hover {
  font-weight: bold;
  cursor: pointer;
}
.col-login a{
  color: #ED1C24 !important;
}
.alert {
  position: fixed !important;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999;
}
.container {
  max-width: 100% !important;
  padding: 0 20px !important;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  color: #3C3F45 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.nav-tabs .nav-link.active {
  border-bottom: none !important;
  color: #ED1C24 !important;
  font-size: 16px !important;
  background-color: #FEE9EA !important;
  padding: 3px 5px;
  margin: 10px 0;
  border-radius: 3px !important;
  font-weight: 600 !important;
}

.tab-pisah.Tab_header_tabs {
  border-bottom: none !important;
}

.tab-pisah .Tab_header_tabs .nav-link.active {
  border-bottom: none !important;
  color: #ED1C24 !important;
  font-size: 16px !important;
  background-color: transparent !important;
  font-weight: bold !important;
}

.tab-gabung .Tab_header_tabs .nav-link {
  font-weight: normal !important;
}

.tab-gabung .Tab_header_tabs .nav-link.active {
  font-weight: normal !important;
  border-bottom: 2px solid #ED1C24 !important;
  color: #ED1C24 !important;
  font-size: 16px !important;
  background-color: transparent !important;
  padding: 15px 0px !important;
  border-radius: 0px !important;
}

.header {
  box-shadow: 0 4px 4px #33373d0c;
}

.btn.btn-sm {
  padding: .3rem .3rem !important;
  margin: 0 !important;
}

.rdt_TableCol_Sortable div {
  white-space: normal !important;
}
.rdt_TableCol_Sortable {
  overflow: visible !important; 
}

.table-responsive {
  min-height: 550px !important;
}
table.mdb-dataTable thead .sorting:before, table.mdb-dataTable thead .sorting_asc:before, table.mdb-dataTable thead .sorting_desc:before, table.mdb-dataTable thead .sorting_asc_disabled:before, table.mdb-dataTable thead .sorting_desc_disabled:before, table thead.mdb-dataTable-head .sorting:before, table thead.mdb-dataTable-head .sorting_asc:before, table thead.mdb-dataTable-head .sorting_desc:before, table thead.mdb-dataTable-head .sorting_asc_disabled:before, table thead.mdb-dataTable-head .sorting_desc_disabled:before,
table.mdb-dataTable thead .sorting:after, table.mdb-dataTable thead .sorting_asc:after, table.mdb-dataTable thead .sorting_desc:after, table.mdb-dataTable thead .sorting_asc_disabled:after, table.mdb-dataTable thead .sorting_desc_disabled:after, table thead.mdb-dataTable-head .sorting:after, table thead.mdb-dataTable-head .sorting_asc:after, table thead.mdb-dataTable-head .sorting_desc:after, table thead.mdb-dataTable-head .sorting_asc_disabled:after, table thead.mdb-dataTable-head .sorting_desc_disabled:after {
  left: auto !important;
  right: 1rem !important;
}

.table,.rdt_Table {
  border: 1px solid #ddd !important;
  border-collapse: separate !important;
  border-left: 0 !important;
  border-radius: 10px !important;
  border-spacing: 0px !important;
}
thead,.rdt_TableHead {
    display: table-header-group !important;
    vertical-align: middle !important;
    border-color: inherit !important;
    border-collapse: separate !important;
}
tr {
    display: table-row !important;
    vertical-align: inherit !important;
    border-color: inherit !important;
}
th, td, .rdt_TableCell,.rdt_TableCol {
    text-align: left !important;
    vertical-align: top !important;
    border-left: 1px solid #ddd !important;    
    color: #3C3F45 !important;
}
td {
    border-top: 1px solid #ddd !important;    
    padding: 0.5rem 0.75rem !important;
    line-height: 2;
}
thead:first-child tr:first-child th:first-child, .rdt_TableHeadRow:first-child .rdt_TableCol:first-child {
    border-radius: 10px 0 0 0 !important;
}
thead:first-child tr:first-child th:last-child, .rdt_TableHeadRow:first-child .rdt_TableCol:last-child {
    border-radius: 0 10px 0 0 !important;
}
tbody:last-child tr:last-child td:last-child, .rdt_TableBody:last-child .rdt_TableRow:last-child .rdt_TableCell:last-child {
    border-radius: 0 0 10px 0  !important;
}
tbody:last-child tr:last-child td:first-child, .rdt_TableBody:last-child .rdt_TableRow:last-child .rdt_TableCell:first-child {
    border-radius: 0 0 0 10px  !important;
}

.rdt_TableHeadRow {
  border-bottom: 2px solid rgba(0,0,0,.12) !important;
}

.rdt_Pagination {
  border: none !important;
}

.text-grey {
  color: #73767A !important;
}

.rdt_TableHeadRow {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
}

.rdt_TableCell {
  font-size: .9rem;
  font-weight: 300;
}

.dashed {
  border-top: 1px dashed #E7E7E8;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em;
  color: #000;
  background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5;
}

.btn, .close {
  box-shadow: none !important;
}

.font-14px {
  font-size: 14px !important;
}

.log-saldo {
  display: block !important;
}

.modal-rounded .modal-content{
  border-radius: 10px !important;
}

.modal-rounded .modal-footer{
  padding: 0.5rem !important;
}

.modal-rounded .btn {
  border-radius: 5px !important;
  text-transform: capitalize;
  font-size: 14px !important;
}

.modal label {
  color: #3C3F45 !important;
}

.btn {
  text-transform: capitalize;
}

.dropdown > button {
  color: #3C3F45 !important;
  background-color: #F1F1F1 !important;
  padding: 5px;
  border-radius: 5px;
  border: none;
  margin: 0;
  box-shadow: none !important;
}

.dropdown .dropdown-item:active {
  background-color: #F1F1F1 !important;
}

a {
  color: #3C3F45 !important;
}

.text-default {
  color: #3C3F45 !important;
}

.loaders {
  position:fixed;
  width:100%;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background-color: rgba(255,255,255,0.9);
  z-index:9999;
  /* display:none; */
}

.aksi-grey i {
  font-weight: bold !important;
}

.loaders svg {
  display:block;
  position:absolute;
  left:48%;
  top:40%;
}

.card.box {
  height: 250px !important;
  background-color: #E7E7E8 !important;
}

.card.box .btn {
  background-color: #fff !important;
}

.btn-light {
  color: #3C3F45;
}

.sc-dmlqKv { 
  overflow-x: visible !important; 
  overflow-y: visible !important; 
}

.navbar-toggler {
  display: none;
}

.dot-notif{
  font-size: 12px;
  vertical-align: top;
  color: #DE3E4D;
}

@media only screen and (max-width: 992px) {
  .navbar-toggler {
    display: block;
  }
}

/* SELECT SEARCH */
.select-search {
  position: relative;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid #E7E7E8;
  border-radius: 3px;
  outline: none;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
  padding-left: 0px !important;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: rgba(180, 180, 180, 0.1);
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(180, 180, 180, 0.1);
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #3C3F45;
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

/*-- Chart --*/
.c3 svg {
  font: 10px sans-serif;
  -webkit-tap-highlight-color: transparent;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;
}

.c3 path,
.c3 line {
  fill: none;
  stroke: rgba(0, 40, 100, 0.12);
}

.c3 text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: px2rem(12px);
}

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}

.c3-chart-arc path {
  stroke: #fff;
}

.c3-chart-arc text {
  fill: #fff;
  font-size: 13px;
}

/*-- Axis --*/
/*-- Grid --*/
.c3-grid line {
  stroke: #f0f0f0;
}

.c3-grid text {
  fill: #aaa;
}

.c3-xgrid,
.c3-ygrid {
  stroke: #e6e6e6;
  stroke-dasharray: 2 4;
}

/*-- Text on Chart --*/
.c3-text {
  font-size: 12px;
}

.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em;
}

/*-- Line --*/
.c3-line {
  stroke-width: 2px;
}

/*-- Point --*/
.c3-circle._expanded_ {
  stroke-width: 2px;
  stroke: white;
}

.c3-selected-circle {
  fill: white;
  stroke-width: 1.5px;
}

/*-- Bar --*/
.c3-bar {
  stroke-width: 0;
}

.c3-bar._expanded_ {
  fill-opacity: 1;
  fill-opacity: 0.75;
}

/*-- Focus --*/
.c3-target.c3-focused {
  opacity: 1;
}

.c3-target.c3-focused path.c3-line,
.c3-target.c3-focused path.c3-step {
  stroke-width: 2px;
}

.c3-target.c3-defocused {
  opacity: 0.3 !important;
}

/*-- Region --*/
.c3-region {
  fill: steelblue;
  fill-opacity: 0.1;
}

/*-- Brush --*/
.c3-brush .extent {
  fill-opacity: 0.1;
}

/*-- Select - Drag --*/
/*-- Legend --*/
.c3-legend-item text {
  fill: #545454;
  font-size: 14px;
}

.c3-legend-item-hidden {
  opacity: 0.15;
}

.c3-legend-background {
  fill: transparent;
  stroke: lightgray;
  stroke-width: 0;
}

/*-- Title --*/
.c3-title {
  font: 14px sans-serif;
}

/*-- Tooltip --*/
.c3-tooltip-container {
  z-index: 10;
}

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  font-size: 11px;
  line-height: 1;
  font-weight: 700;
  color: #fff;
  border-radius: 3px;
  background: #212529 !important;
  white-space: nowrap;
}

.c3-tooltip th {
  padding: 6px 6px;
  text-align: left;
}

.c3-tooltip td {
  padding: 4px 6px;
  font-weight: 400;
}

.c3-tooltip td > span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
  vertical-align: baseline;
}

.c3-tooltip td.value {
  text-align: right;
}

/*-- Area --*/
.c3-area {
  stroke-width: 0;
  opacity: 0.1;
}

.c3-target-filled .c3-area {
  opacity: 1 !important;
}

/*-- Arc --*/
.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em;
}

.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: none;
}

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #000;
  font-size: 16px;
}

.c3-chart-arcs .c3-chart-arcs-gauge-max {
  fill: #777;
}

.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #777;
}

.c3-chart-arc .c3-gauge-value {
  fill: #000;
  /*  font-size: 28px !important;*/
}

.c3-chart-arc.c3-target g path {
  opacity: 1;
}

.c3-chart-arc.c3-target.c3-focused g path {
  opacity: 1;
}

.c3-axis {
  fill: #9aa0ac;
}

/* COSTUM */
.avatar {
  border-radius: 5px !important;
}

.header-brand-img {
  margin-left: 1rem !important;
}

/* .header {
  height: 70px;
  position: fixed;
  width: 100%;
  z-index: 1;
}
.layout {
  display: flex;
  min-height: 100%;
}
.menu-sidebar.nav {
  height: 100%;
  width: 250px;
  position: fixed;
  top: 70px;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  padding: 20px;
}
.menu-sidebar.nav > .nav-link {
  padding: 10px;
  color: #909399 !important;
  padding: 10px;
  margin-bottom: 5px;
}
.menu-sidebar.nav > .nav-link.active {
  padding: 10px;
  color: #FFFFFF !important;
  background-color: #EB4B56;
  border-radius: 5px;
  font-weight: 700;
}
.menu-sidebar.nav > .nav-link > i {
  padding-right: 10px !important;
  font-size: 18px;
}
 */
 
.breadcrumb {
  background-color: transparent;
  padding: 0 1rem;
}
.breadcrumb-item > a {
  color: #3B3C3D !important;
}
.breadcrumb-item.active {
  color: #EB4B56 !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #3B3C3D;
}

.title {
  padding: 0 1rem;
}
/* 
.main-layout {
  width: calc(100% - 250px);
  position: absolute;
  top: 70px;
  left: 250px;
  padding: 20px 0 0 20px;
}

.main-layout > .main {
  padding: 0 1rem;
}

@media only screen and (max-width: 600px) {
  .flex-column.nav {
    display: none;
  }
  .main-layout {
    width: 100%;
    left: 0;
    padding: 10px 0 ;
  }
} */

.btn-light {
  background-color: #f5f5f5;
}

#custom-switch
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  padding-left: 1.5rem;
}

.filter-button {
  border-radius: 20px;
  background-color: #FFFFFF;
  border-color: #DCDFE5;
  margin-right: 10px;
  font-weight: normal;
  min-width: auto !important;
}

.filter-button:hover {
  background-color: #FFFFFF;
  color: #000;
  font-weight: bold;
}

.img-rounded {
  border-radius: 20px 20px 0 0;
}

.card-rounded {
  border-radius: 20px;
}

.artis-footer {
  max-width: 450px;
  margin-left: auto;
}

.artis-paginate {
  display: flex;
  list-style-type: none;
  padding-left: 0;
  text-align: right;
  justify-content: flex-end;
}

.artis-paginate li a{
  background-color: #FFFFFF;
  border-top: 1px solid #DCDFE5;
  border-bottom: 1px solid #DCDFE5;
  border-right: 1px solid #DCDFE5;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.artis-paginate li:first-child a{
  border-left: 1px solid #DCDFE5;
  border-radius: 5px 0 0 5px;
}

.artis-paginate li:last-child a{
  border-radius: 0 5px 5px 0;
}

.artis-paginate li.disabled a {
  color: #C0C4CC !important;
}

.artis-paginate li.selected a {
  color: #FFFFFF !important;
  background-color: #EB4B56 !important;
}

.overflow-auto {
  overflow: auto;
}

.tanggal-riwayat {
  color: #3B3C3D !important;
}

.tanggal-riwayat.active {
  background-color: #EB4B56 !important;
  color: #FFFFFF !important;
}

.filter-data {
  border: 1px solid #DCDFE5;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.artis-icon {
  max-width: 40px;
}

.profil-icon {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: 100% 0;
  border-radius: 50%;
}

.h-dashed {
  border-top: 2px dashed #DCDFE5;
  height: 0 !important;
}

.badge-outline-warning {
  background: #FFFFFF;
  border: 1px solid #FFD480;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(255, 178, 26, 0.24);
  border-radius: 24px;
  color: #3B3C3D;
  font-size: 14px;
}

.badge-primary {
  background: #19A3FF;
  border: 1px solid #19A3FF;
  box-sizing: border-box;
  color: #FFFFFF;
}

.badge-outline-primary {
  background: #EBF7FF;
  border: 1px solid #EBF7FF;
  box-sizing: border-box;
  color: #19A3FF;
}

.badge-outline-success {
  background: #EBFFED;
  border: 1px solid #EBFFED;
  box-sizing: border-box;
  color: #00B815 !important;
}

.border-card.line-warning {
  border: 2px solid #FFD480 !important;
}

.custom-radios {
  border: 1px solid #DCDFE5;
  padding: 7px 15px;
  width: 40%;
  border-radius: 2px;
}

.custom-radios .custom-control-label::before, .custom-radios .custom-control-label::after {
  left: unset !important;
  right: 15px !important;
  top: 0.5rem !important;
}

.custom-radios.active  {
  border: 1px solid #EB4B56 !important;
  background-color: #FFEBEC !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #EB4B56 !important;
}

.w-30 {
  width: 28%;
}

.page-top {
  margin-top: 100px !important;
}

.page-top-2 {
  margin-top: 68px !important;
}

.font-12{
  font-size: 12px !important;
}

.nav-tabs .nav-link {
  font-weight: normal !important;
}

.nav-tabs .nav-link.active {
  font-weight: normal !important;
}

.font-20 {
  font-size: 20px;
}

.img-artis,.image-artis {
  border-radius: 10px;
  opacity: 1;
  display: block;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: 100% 0;
}

.image-artis.video {
  height: 200px !important;
  align-items: center;
  justify-content: center;
  display: flex;
}

.image-artis:hover .img-artis {
  opacity: 0.5;
}

.image-artis:hover .middle {
  opacity: 1;
}

.image-artis {
  background-color: #000;
  position: relative;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.progress {
  height: 10px;
}

.progress-bar {
  height: 10px;
}

.custom-input-file {
  border: 1px solid #DCDFE5;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.custom-input-file input {
  display: none;
}

.custom-input-file span {
  font-size: 14px;
  color: #73767A;
}

.image-upload {
  display: inline-flex;
  align-items: center;
}

.image-upload .form-group {
  width: 100%;
}

.image-upload img {
  max-width: 100px;
  border-radius: 10px !important;
  margin-right: 20px;
}

.image-artis .img-composite {
  border-radius: 10px;
}

.imagecheck-figure:before {
  background-color: #EB4B56;
}

.imagecheck-input:checked ~ .imagecheck-figure,.imagecheck-input:focus ~ .imagecheck-figure {
  border: 2px solid #FF858D;
}

.imagecheck-input:checked ~ .imagecheck-figure:after {
  content: attr(datavalue);
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  background: #fff;
  border: 1px solid #EB4B56;
  color: #EB4B56;
  border-radius: 50%;
  line-height: 1.2rem;
  text-align: center;
}

.imagecheck-figure img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: 100% 0;
}

@media (min-width: 992px) {
  .custom-width-modal .modal-dialog {
    max-width: 900px;
  }
}

.img-composite-logo {
  max-width: 147px;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

#custom-width-modal div {
  background: #fff;
}

.lightbox div {
  z-index: 99999;
}

.rounded-card {
  border-radius: 10px;
}
/* s.news css */
.mini-nav {
  color: #3B3C3D !important;
}

.mini-nav.active {
  background-color: #FFEBEC !important;
  color: #EB4B56 !important;
}
/* e.news css */

.bg-light-primary {
  background: #D6EEFF;
}
.text-dark-primary {
  color: #19A3FF;
}

.img-badge {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 48px;
}

.img-badge span {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #FFFFFF;
  font-weight: bold;
}

.modal-convert > .modal-content {
  border: none !important;
}

.modal-convert {
  background-image: linear-gradient(to bottom left, #EB4B56, #ffa880);
  border: none;
  color: #FFFFFF;
}

.modal-convert .image-circle img {
  margin: auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  padding: 5px;
  border: 2px dashed #FFFFFF;
  object-fit: cover;
  object-position: 100% 0;
}

.btn-outline-transparant {
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  background: transparent;
  width: 200px;
}
.btn-outline-transparant:hover,.btn-light-transparant:hover {
  color: #EB4B56;
  border: 1px solid #EB4B56;
}

.btn-light-transparant {
  color: #FFFFFF;
  border: none;
  background: rgba(255, 255, 255, 0.32);
  width: 200px;
}

.btn-none i{
  font-size: 2em;
}

.btn-list {
  display: flex;
  justify-content:  space-between;
  color: #3B3C3D !important;
  border-bottom: 1px solid #DCDFE5;
  font-size: 14px;
  font-weight: normal;
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
}

.bagan-card {
  border: 1px solid #E9EBF0;
  border-radius: 8px;
}

.bagan-card button:first-child {
  background-color: #fff !important;
}

.artis button:first-child {
  width: 100%;
}

.btn {
  border-radius: 8px !important;
}

.bagan-pemeran .null {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #909399;
  height: 64px;
  border: 1px dashed #DCDFE6;
  box-sizing: border-box;
  border-radius: 8px;
}

.bagan-pemeran img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  object-position: 100% 0;
  border-radius: 4px;
}

.bagan-pemeran .btn {
  height: 35px;
  padding: 5px;
}

.komentar-image {
  border-radius: 50%;
  width: 55px;
  height: 55px;
  object-fit: cover;
  object-position: 100% 0;
}

.bagan-sp {
  display: flex;
}

.bagan-sp .list-sp {
  position: relative;
  width: 120px;
  height: 180px;
  margin-right: 15px;
}

.bagan-sp .list-sp img {
  height: 100%;
  object-fit: cover;
  object-position: 50% 0;
}

.input-zoom {
  width: 170px !important;
}

.collapse-bagan-panel {
  padding-bottom: 10px;
  background: #fff;
  border: none !important;
}

.collapse-bagan-panel .ant-collapse-header {
  flex-direction: row-reverse;
  justify-content: start !important;
  background-color: #F5F5F7;
}

.collapse-bagan-panel .ant-collapse-header div:first-child {
  margin-left: auto;
}

.collapse-bagan-panel.ant-collapse-item-active .ant-collapse-header {
  background-color: #FFEBEC !important;
  color: #EB4B56;
  border: none !important;
}

.casting-bagan-component {
  width: 80px;
  height: 106px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 28px 20px; */
  background: #FFFFFF;
  border: 1px dashed #73767A;
  box-sizing: border-box;
  border-radius: 8px;
}

.casting-bagan-component img {
  width: 80px;
  height: 105px;
  object-fit: cover;
  object-position: 100% 0;
  border-radius: 8px;
}

.casting-bagan-component i {
  color: #ADB0B8;
  font-size: 36px;
}

.e-acrdn-item,.e-accordion {
  border: none;
  padding-bottom: 10px;
  background: #fff;
  border-bottom: none !important;
}

.e-accordion .e-acrdn-item>.e-acrdn-header {
  background: #F5F5F7;
}

.e-accordion .e-acrdn-item.e-selected>.e-acrdn-header,.e-accordion .e-acrdn-item.e-selected.e-select.e-expand-state>.e-acrdn-header:focus,.e-accordion .e-acrdn-item .e-acrdn-header:focus {
  background: #FFEBEC;
  border: none;
  box-shadow: none;
}

.e-accordion .e-acrdn-item>.e-acrdn-header>.e-toggle-icon, .e-accordion .e-acrdn-item>.e-acrdn-header>.e-acrdn-header-content {
  font-weight: bold;
}

.e-accordion .e-acrdn-item.e-selected>.e-acrdn-header>.e-toggle-icon, .e-accordion .e-acrdn-item.e-selected>.e-acrdn-header>.e-acrdn-header-content {
  color: #EB4B56;
  font-weight: bold;
}

.img-close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
}

.img-close .btn {
  padding: 0;
  min-height: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
}

.img-close .btn i {
  vertical-align: 0px;
}

.ant-switch-checked{
  background-color: #EB4B56;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #EB4B56;
  border-color: #EB4B56;
}

.img-card-bookmark{
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: 100% 0;
}

.img-list-artis {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: 100% 0;
}

@media (min-width: 1600px) {
  .img-list-artis {
    height: 350px;
  }
}

.img-composite-primary {
  width: 400px;
  height: 600px;
  object-fit: cover;
  /* object-position: 100% 0; */
}
.img-composite-secondary {
  width: 200px;
  height: 296px;
  object-fit: cover;
  /* object-position: 100% 0; */
}


.max-body {
  overflow:scroll;
  height:400px;
}

.w-250px {
  width: 250px;
}

.casting-scroll {
  position: absolute;
  overflow-y: scroll;
  height: 720px;
  right: 0;
}

.casting-scroll2 {
  position: absolute;
  overflow-y: scroll;
  height: 640px;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 25px;
}

.btn-jadwal {
  padding: 0 !important;
  line-height: normal !important;
  min-width: auto !important;
  width: 30px;
  height: 30px;
}

.timepicker {
  width: 65px;
}

.artist-video {
  height: 100%;
}

.artist-video video {
  max-width: 100%;
  height: 100%;
}

#connectors .e-symbol-draggable {
  width: 87px !important;
  height: 102px !important;
}

/* .e-dragclone {
  position: relative !important;
} */

.text-circle {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: #fff;
  background: #FFEBEC;
  color: #EB4B56;
  text-align: center;
  margin-right: 10px;
  line-height: 30px;
  font-weight: bold;
  font-size: 20px;
}

#componentPalette {
  overflow: unset !important;
}

.componentPalette-card {
  overflow-y: auto !important;
  height: 720px !important;
}